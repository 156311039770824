//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import Avatar from '@/components/Avatar.vue'
export default {
    name: "EventCards",
    components: {Avatar},
    props:["event"],
    data(){
        return {
            user_id: this.$store.state.user.id,
            image: '',
            event_host: ''
        }
    },
    computed:{
        isOwner(){
            return this.event.user_id == this.$store.state.user.id ? true : false;
        }
    },  
    methods:{
        fetchThumbnails(id){
            this.payload = {
                id:id
            }
            this.$store.dispatch('event/getCoverPhoto',this.payload)
            .then((r) => {
                r.status == 'success' ? this.image = r.image : ''
            })
        },
        loadUser(id){
            this.$store.dispatch('profile/getUser',id)
            .then((r) => {
                if(r.type == 'success'){
                    this.event_host = r.name
                }
            })
        },
    //     joinEvent(id){
    //             this.$http({
    //             url: '/events/' + id + '/join',
    //             method: 'POST'
    //             }).then((response) => {
    //             if (response.data['status'] == 'event_joined') {
    //                 this.$q.notify({
    //                     message: 'You have successfully joined this event.',
    //                     color: 'positive',
    //                     icon: 'check_circle'
    //                 });
    //                 this.$router.push({name: 'Event', params: {id: id}});

    //                 return;
    //             }
    //             if (response.data['status'] == 'already_member') {
    //                 this.$q.notify({
    //                     message: 'You are already a part of this event.',
    //                     color: 'primary',
    //                     icon: 'feedback'
    //                 });
    //                 this.$router.push({name: 'Event', params: {id: id}});

    //                 return;
    //             }
    //             if (response.data['status'] == 'event_private') {
    //                 this.$q.dialog({
    //                     message: 'This event has switched to private and only the event captain can invite members into the event.',
    //                     title: 'This event is private'
    //                 });

    //                 return;
    //             }
    //         })
    //     }
    },
    filters: {
        filterEventDate: function (date,date_end) {
           return date_end == date ? moment(new Date(date)).format('MMM DD YYYY') :   moment(new Date(date)).format('MMM DD') + ' - ' + moment(new Date(date_end)).format('MMM DD YYYY')
        },
    },
    created(){
        this.fetchThumbnails(this.event.id)
        this.loadUser(this.event.user_id)
    }
}
