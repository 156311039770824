//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingSkeleton from '@/components/LoadingSkeleton.vue'
import EventCards from '@/components/Event/EventCards.vue'
import EmptyMessage from '@/components/EmptyMessage'

export default {
    components:{
        EventCards, EmptyMessage, LoadingSkeleton,
        LeftSidebar: () => import('@/components/Sidebars/LeftSidebar.vue')
    },
    data(){
        return {
            user_id: this.$store.state.user.id,
            page: 1,
            pagination: null,
            events: null,
            isEmpty: false,
            searchText: '',
            isDark: false,
            isLoading: true,
        }
    },
    metaInfo(){
        return {
            title:'Events'
        }
    },
    methods: {
        search(){
            this.q = this.searchText
            this.isLoading = true;
            this.fetchAllEvents()
        },
       fetchAllEvents(){
           this.payload = {
               page: this.page,
               q: this.searchText
           }
           this.$store.dispatch('event/fetchEvents', this.payload)
           .then((r) => {
               if(r.status == 'success'){
                   this.pagination = r.pagination
                   this.events = this.$store.state.event.events
               }else{
                   this.isEmpty = true
               }
               this.isLoading = false
           })
       },
       changePage(page){
           this.fetchAllEvents(page)
       },
    },
    created() {
        this.fetchAllEvents();
    }
}
